<template>
  <v-list-group :value="defaultValue" no-action>
    <template #activator>
      <v-list-item-icon>
        <v-icon color="primary">
          {{ item.icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ $t(item.name) }}
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <!-- Multiline -->
    <v-list v-if="item.multiline">
      <v-list-item-group>
        <template v-for="subItem in item.subItems">
          <sidebar-custom-button
            v-if="subItem.type === 'button'"
            :key="subItem.id"
            :item="subItem"
          />
          <v-list-item
            v-else
            :key="subItem.name"
            :value="subItem"
            :to="subItem.route" link
            three-line
          >
            <v-list-item-subtitle v-text="subItem.name" />
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
    <!-- Default -->
    <template v-else>
      <template v-for="subItem in item.subItems">
        <sidebar-custom-button
          v-if="subItem.type === 'button'"
          :key="subItem.id"
          :item="subItem"
        />
        <v-list-item v-else :key="subItem.id" :to="subItem.route" link>
          <v-list-item-title>
            {{ $t(subItem.name) }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </template>
  </v-list-group>
</template>

<script>
export default {
  name: 'SidebarSubItems',
  components: {
    SidebarCustomButton: () => import('@/components/sidebarItems/SidebarCustomButton'),
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    route: {
      type: Object,
      default: null,
    },
  },
  computed: {
    defaultValue: ({ item }) => item.name === 'project.sections.settings',
  },
}
</script>

<style lang="scss">

.v-list-group__items .v-list-item--active .v-list-item__title {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 700;
}

.v-list-item {

  .sidebar-subitem-icon {
    width: 71px;
  }

  .v-list-item__subtitle {
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: 400;
  }

  .v-list-item__content .v-list-item__title {
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: 400;
  }

  &[href]:not(.v-list-item--active) .v-list-item__title {
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: 400;
  }
}
</style>
